<template>
  <div>
    <v-card outlined>
      <v-card-text>
        <span class="d-flex flex-row justify-space-between align-center">
          <span class="d-flex flex-row align-center">
            <h2 class="mr-2"><span v-if="useDocumentNameInHeader">{{documentName}}</span> Sketches</h2>
            <v-btn v-if="!settingsMode && settings.enabled && ((documentStatus > -1 && documentStatus < 1 ) || settings.editPostSeal)" :disabled="missingProps.length > 0" @click="openAddSketchDialog('receipt')" fab x-small color="success" class="mr-2"><v-icon>mdi-printer-pos</v-icon></v-btn>
            <v-btn v-if="!settingsMode && settings.enabled && ((documentStatus > -1 && documentStatus < 1 ) || settings.editPostSeal)" :disabled="missingProps.length > 0" @click="openAddSketchDialog('letter')" fab x-small color="success"><v-icon>mdi-printer</v-icon></v-btn>
          </span>
          <v-btn v-if="isAllowed('sketch', 'mc')" @click="toggleSettingsMode" icon>
            <v-icon v-if="!settingsMode">mdi-cog</v-icon>
            <v-icon v-else>mdi-close</v-icon>
          </v-btn>
        </span>
        <div v-if="(gallery.length > 0) && (missingProps.length === 0) && !settingsMode" class="d-flex flex-row flex-wrap mt-2">
          <div v-for="(item,index) in gallery" :key="index" style="background-color: #fff; border: 1px solid grey; border-radius: 7px; width: 60px; height: 60px;" class="mr-1 mb-1">
            <div style="position: absolute; width: 60px; height: 60px;">
              <a :href="getEndpoint+'/'+item.url" target="__blank" style="position: absolute; width: 100%; height: 100%; margin-top: 0px;">
                <img :src="getEndpoint+'/'+item.url" alt="" width="100%;" height="100%;">
              </a>
              <div v-if="((documentStatus > -1 && documentStatus < 1 ) || settings.editPostSeal)" style="position: absolute; width: 90%; z-index:1;" class="d-flex flex-row justify-space-between align-start mt-1">
                <v-btn @click="openAttachToPrintsDialog(index)" icon x-small color="success"><v-icon>mdi-paperclip-plus</v-icon></v-btn>
                <confirmedActionButton
                  color="error" 
                  small
                  icon
                  @cb="deleteSketch(index)"
                  fabIcon="mdi-close"
                  :dialogText="'Are you sure you wish to proceed?'"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-if="missingProps.length > 0">
          <h3 style="color: red;">⚠️ Configuration error: Missing Props:</h3>
          <div v-for="(item,index) in missingProps" :key="index">-{{ item }}</div>
        </div>
        <div v-if="settingsMode">
          <h3>Settings</h3>
          <v-text-field class="mt-3" dense outlined v-model="settings.defaultPenColor" label="Default Pen Color"></v-text-field>
          <v-checkbox hide-details class="mb-2" dense v-model="settings.monochrome" label="Monochrome"/>
          <v-checkbox hide-details class="mb-2" dense v-model="settings.attachFirstSketchToPrintables" label="Attach first sketch to all Printables"/>
          <v-checkbox hide-details class="mb-2" dense v-model="settings.editPostSeal" label="Modify beyond Seal"/>
          <v-checkbox hide-details class="mb-2" dense v-model="settings.enabled" label="Enabled"/>
          <v-btn x-small color="success" :loading="settingsIsLoading" @click="saveSettings">Save</v-btn>
        </div>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="attachToPrintsDialog.isOpen"
      persistent
      width="600"
    >
      <v-card>
        <v-card-title class="text-h5 d-flex flex-column align-center justify-center">
          <b>Attach Sketch to Printables</b>
        </v-card-title>
        <v-card-text>
          <v-select
            v-if="printOptions"
            outlined
            chips
            multiple
            label="Select the Print Options"
            :items="printOptions"
            item-text="name"
            item-value="documentTemplate"
            v-model="selectedItems"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn text  @click="closeAttachToPrintsDialog()">
            Cancel
          </v-btn>
          <v-btn color="info" @click="attachToPrints()">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      fullscreen
      hide-overlay
      style="z-index: 9999;"
      v-model="dialog.isOpen"
      persistent
      max-width="1200"
    >
      <v-card>
        <v-card-title class="text-h5 d-flex flex-column align-center justify-center">
          <b>Sketch Module v2.0</b>
        </v-card-title>
        <v-card-text style="width: 100%" class="d-flex flex-column align-center justify-center">
          <div class="d-flex flex-column align-center">
            <div class="d-flex flex-row">
              <v-btn @click="undoSketch" color="info" class="mr-2">Undo</v-btn>
              <v-btn @click="clearSketch" color="warning" class="mr-2">Clear</v-btn>
              <v-btn @click="saveTheSketch" color="success" class="mr-2">Save</v-btn>
              <v-btn @click="closeDialog" color="error" >Cancel</v-btn>
            </div>
            <div v-if="!settings.monochrome" class="d-flex flex-row align-center mt-3">
              <div v-for="(item,index) in penColors" :key="index">
                <div @click="changePenColor(index)" class="mr-1" :style="(item.isSelected?'border: 3px solid chartreuse;':'border: 2px solid grey;')+'width: 25px; height: 25px; border-radius: 25px; background-color:'+item.value+';'"></div>
              </div>
            </div>
          </div>
          <VueSignaturePad 
            v-if="dialog.isOpen" 
            class="mt-2"
            :width="width" 
            :height="height"
            style="border: 2px solid grey; border-radius: 10px;" 
            ref="sketch"
            :options="{
              penColor: options.penColor,
              onBegin: onBegin, 
              onEnd: checkSketchValid
            }" 
          />
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import confirmedActionButton from './confirmedActionButton.vue'
import axios from 'axios'
export default {
  components: {
    confirmedActionButton,
  },
  name: 'sketch',
  props: {
    documentName: String,
    documentId: Number,
    useDocumentNameInHeader: Boolean,
    text: Boolean,
    icon: String,
    fabIcon: String,
    buttonText: String,
    dialogText: String,
    color: String,
    fab: Boolean,
    sketchPadWidth: String,
    sketchPadHeight: String,
    small: Boolean,
    xsmall: Boolean,
    large: Boolean,
    xlarge: Boolean,
    disabled: Boolean,
    requireUsername: Boolean,
    requirePassword: Boolean,
    requireReason: Boolean,
    loading: Boolean,
    metadata: Object,
    documentSketches: Array,
    documentPrintablesDBCID: String,
    id: String,
    documentStatus: Number,
  },
  data: () => ({
    dialog: {
      isOpen: false,
      text: '',
    },
    settingsMode: false,
    gallery: [],
    viewSketchDialog: {
      isOpen: false
    },
    penColors: [
      {value: 'black', isSelected: false},
      {value: 'white', isSelected: false},
      {value: 'red', isSelected: false},
      {value: 'blue', isSelected: false},
      {value: 'green', isSelected: false},
      {value: 'magenta', isSelected: false},
      {value: 'pink', isSelected: false},
      {value: 'orange', isSelected: false},
      {value: 'yellow', isSelected: false},
    ],
    options: {
      penColor: 'blue'
    },
    username: null,
    password: null,
    reason: null,
    showPassword: false,
    width: "1000px",
    height: "500px;",
    attachToPrintsDialog: {
      isOpen: false,
      isLoading: false,
      data: {}
    },
    requiredProps: [
      "documentPrintablesDBCID",
      "documentName",
      "documentId",
    ],
    missingProps: [],
    printOptions: [],
    selectedItems: [],
    value: [],
    settings: {},
    settingsIsLoading: false,
  }),
  async mounted(){
    try {
      for(let i of this.requiredProps){
        console.log(this[i])
        if(!this[i] && this[i] !== 0){
          this.missingProps.push(i)
        }
      } 

      if(this.sketchPadWidth){
        this.width = this.sketchPadWidth
      }
      if(this.sketchPadHeight){
        this.height = this.sketchPadHeight
      }
      this.options.backgroundColor = 'white'

      await this.getAllData()
    } catch (error) {
     console.log(error) 
    }
  },
  computed: {
    ...mapGetters(['getEndpoint', 'getGlobalValue', 'isAllowed']),
  },
  methods: {
    // ...mapMutations([]),
    async getAllData(){
      try {
        let res = await axios.get(`${this.getEndpoint}/api/sketches/${this.documentName}/${this.documentId}`)
        if(res.data.error) throw res.data.error
        this.gallery = res.data
        this.settings = this.getGlobalValue("VEC_SKETCH_CONFIG").find(x=>x.id===this.id)
        
        if(this.penColors.filter(x=>x.isSelected==true).length == 0){
          this.options.penColor = this.settings.defaultPenColor
        }
      } catch (error) {
        console.log("errr",error)
      }
    },
    async toggleSettingsMode(){
      try {
        this.settingsMode = !this.settingsMode
        if(this.settingsMode){
          this.settings = this.getGlobalValue("VEC_SKETCH_CONFIG").find(x=>x.id===this.id)
        }
      } catch (error) {
        console.log(error)
      }
    },
    async saveSettings(){
      try {
        this.settingsIsLoading = true
        
        let res = await axios.put(`${this.getEndpoint}/api/sketches/settings/${this.id}`, this.settings)
        if(res.data.error) throw res.data.error
        
        await this.getAllData()
      } catch (error) {
        console.log("errr",error)
      } finally {
        this.settingsIsLoading = false
      }
    },
    async openAttachToPrintsDialog(index){
      try {
        let res = await axios.get(`${this.getEndpoint}/api/dynamicButtonContainers/${parseInt(this.documentPrintablesDBCID)}`)
        if(res.data.error) throw res.data.error
        this.attachToPrintsDialog = {
          isOpen: true,
          isLoading: false,
          index: index,
        }
        let x = res.data.data.dynamicButtons.data.filter(x=>x.documentTemplate).map(x=>{return {name: x.name, documentTemplate: x.documentTemplate}})
        this.printOptions = x
        this.selectedItems = res.data.data.dynamicButtons.data.filter(x=>(this.gallery[index].prints.includes(x.documentTemplate))).map(x=>{return {name: x.name, documentTemplate: x.documentTemplate}})
      } catch (error) {
        console.log(error)
      }
    },
    async attachToPrints(){
      try {
        let res = await axios.put(`${this.getEndpoint}/api/sketches/${this.documentName}/${this.documentId}/${this.attachToPrintsDialog.index}`, {prints: this.selectedItems})
        if(res.data.error) throw res.data.error
        console.log(res.data.data)
        await this.getAllData()
      } catch (error) {
        console.log(error)
      } finally {
        this.closeAttachToPrintsDialog()
      }
    },
    closeAttachToPrintsDialog(){
      this.attachToPrintsDialog = {
        isOpen: false,
        isLoading: false,
        index: null
      }
    },
    resize(size){
      size
      this.width = "1000px;"
    },
    async deleteSketch(index){
      try {
        let res = await axios.delete(`${this.getEndpoint}/api/sketches/${this.documentName}/${this.documentId}/${index}`)
        if(res.data.error) throw res.data.error
        await this.getAllData()
      } catch (error) {
        console.log(error)

      }
    },
    changePenColor(index){
      for(let i = 0; i < this.penColors.length; i++){ // reset previously selected
        let arr = this.penColors[i]
        arr.isSelected = false
        this.penColors.splice(i, 1, arr)
      }

      let x = this.penColors[index]

      this.options.penColor = x.value
      x.isSelected = true
      this.penColors.splice(index, 1, x)
    },
    onBegin(){
      this.$refs.sketch.resizeCanvas()
    },
    checkSketchValid(){
      console.log('fired')
      if(this.$refs.sketch.isEmpty()){
        this.dialog.isValid = false
      }else{
        this.dialog.isValid = true
      }
    },
    clearSketch() {
      this.$refs.sketch.clearSignature();
      this.checkSketchValid()      
    },
    undoSketch() {
      this.$refs.sketch.undoSignature();
      this.checkSketchValid()      
    },
    async saveTheSketch(){
      try {
        this.dialog.isLoading = true

        const { isEmpty, data } = this.$refs.sketch.saveSignature()
        if(isEmpty){
          console.log("No sketch provided.")
          this.dialog.isLoading = false
          return
        }
        let formData = new FormData()
        let img = data
        formData.append('images', img)
        let res = await axios.post(`${this.getEndpoint}/api/sketches/${this.documentName}/${this.documentId}`, formData, {
          header : {
            'Content-Type' : 'multipart/form-data'
          }
        })
        if(res.data.error) throw res.data.error
        this.closeDialog()
        await this.getAllData()
      } catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error");
      } finally {
        this.dialog.isLoading = false;
      }
    },
    async openViewSketchDialog(){
      this.viewSketchDialog.isOpen = true
    },
    closeViewSketchDialog(){
      this.viewSketchDialog.isOpen = false
    },
    async openAddSketchDialog(type){
      try {
        this.dialog.isOpen = true
        this.dialog.isValid = false
        
        let x = ""
        if(type === 'letter'){
          x = "1200px"
        }
        if(type === 'receipt'){
          x = "600px"
        }
        
        this.width = x
        this.dialog.data = {}
      } catch (error) {
        console.log(error)
      }
    },
    closeDialog(){
      this.dialog = {
        isOpen: false,
        isValid: false,
        data: {}
      }
      this.username = null
      this.password = null
      this.reason = null
      this.showPassword = false
    },
    confirm(){
      let obj = {
        username: this.username,
        password: this.password, 
        reason: this.reason,
        metadata: this.metadata
      }
      this.$emit('cb', obj)
      this.closeDialog()
    },
  }
}
</script>
